<!--配合比查看-->
<template>
    <div class="viewMixProportion" v-loading="Loading">
        <div class="head_title_container">
            <div>
                <div class="side1"></div>
                <div class="side2"></div>
                <span class="title">
                    {{ productFormula.mark_number }}
                    {{ productFormula.pouring_name }}
                    {{ productFormula.project_name }}
                    {{ productFormula.place_detail }}
                </span>
                <div class="side2"></div>
                <div class="side1"></div>
            </div>
        </div>
        <div class="right-box">
            <div class="mix-time-line">
                <div class="title">
                    配合比调整时间轴
                    <div class="peo-box">
                        <span class="adjust-peo">调整人</span>
                        <span class="approver-peo">审批人</span>
                        <span class="versions-peo">版本号</span>
                    </div>
                </div>
                <div class="time-line-box">
                    <div
                        v-for="item in timeRecords"
                        :key="item.adjust_time"
                        :class="{'time-line-item':true,active:item.adjust_id == adjust_id}"
                        @click="changeTimeRecord(item)"
                    >
                        <div class="pot-info">
                            <div class="peo-names">
                                <div class="peo-name peo1">
                                    {{ item.adjust_user }}
                                </div>
                                <div class="peo-name peo2" v-if="item.approval_user_name">
                                    {{ item.approval_user_name }}
                                </div>
                                <div class="peo-name peo3">
                                    V{{ item.versions }}
                                </div>
                            </div>
                            <div class="date">
                                调整时间：{{ item.adjust_time }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="time-line-box">
                    <div
                        v-for="(item,index) in timeRecords"
                        :key="item.adjust_time"
                        :class="{'time-line-item':true,active:item.adjust_id == adjust_id}"
                        @click="changeTimeRecord(item)"
                    >
                        <div class="pot-info">
                            <div class="peo-names">
                                <div class="peo-name peo1">
                                    {{ item.adjust_user }}
                                </div>
                                <div class="peo-name peo2" v-if="item.approval_user_name">
                                    {{ item.approval_user_name }}
                                </div>
                            </div>
                            <div class="date">
                                <span>{{ item.adjust_time.substr(0,10) }}</span><label>V{{ item.versions }}</label>
                            </div>
                            <span class="times">{{ item.adjust_time.substr(11) }}</span>
                        </div>
                        <i v-if="timeRecords.length===index+1" class="endIcon iconfont iconiconset0416"></i>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="wrapper-box">
            <div class="item">
                <div>塌落度:</div>
                <div>{{ productFormula.slump }}</div>
            </div>
            <div class="item">
                <div>是否出资料:</div>
                <div>
                    <el-radio-group
                        v-model="productFormula.is_datum"
                        disabled
                    >
                        <el-radio :label="true">
                            是
                        </el-radio>
                        <el-radio :label="false">
                            否
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="item">
                <div>产生抗压试验:</div>
                <div>
                    <el-radio-group
                        v-model="productFormula.is_testblock"
                        disabled
                    >
                        <el-radio :label="true">
                            是
                        </el-radio>
                        <el-radio :label="false">
                            否
                        </el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div class="item">
                <div>备注：</div>
                <div>{{ productFormula.remarks }}</div>
            </div>
        </div>
        <div class="base-info">
            <div class="left-box">
                <!-- <h1 class="title">
                    ({{ productFormula.mark_number }}
                    {{ productFormula.pouring_name }}
                    {{ productFormula.project_name }}
                    {{ productFormula.place_detail }})
                </h1> -->
                <div class="machine-list">
                    <div class="carousel_box">
                        <el-carousel height=".92rem" :autoplay="false" indicator-position="outside">
                            <el-carousel-item v-for="(val, i) in mixstationlineDataCarousel" :key="i">
                                <div class="item_box">
                                    <div
                                        v-for="(item, index) in val"
                                        :key="`crew_${index}`"
                                        :class="{'machine-item':true,'active': mixstationIndex == item.line_code}"
                                        @click="switchingUnit(item, index)"
                                    >
                                        <div :class="{machine_title: true, machine_save: item.product_formula_number_hjt && !item.sync_status}">
                                            {{ item.line_name }}
                                            <span v-if="item.product_formula_number_hjt && !item.sync_status">已保存</span>
                                            <i class="icon toSave" v-if="item.product_formula_number_hjt && !item.sync_status"></i>
                                            <span v-if="item.sync_status">已发送</span>
                                            <i class="icon toSend" v-if="item.sync_status"></i>
                                        </div>
                                        <span>砼配比:{{ item.product_formula_number_hjt ? item.product_formula_number_hjt : '--' }}</span>
                                        <span>砂浆配比:{{ item.product_formula_number_sj ? item.product_formula_number_sj : '--' }}</span>
                                        <div class="active_icon" v-if="mixstationIndex == item.line_code"></div>
                                        <!-- <div class="send-btn active">-->
                                        <!--  <div class="send-btn">-->
                                        <!--       发送-->
                                        <!--   </div>-->
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <!-- <div class="wrapper-box">
                    <div class="item">
                        <strong>试验配比名称：{{ productFormulaCount.experiment_formula_name }}</strong>
                    </div>
                    <div class="item">
                        <strong>试验配比编号：{{ productFormulaCount.line_experiment_formula_number }}</strong>
                    </div>
                    <div class="item">
                        <div class="item">
                            <label>配比类型:</label>
                            <el-radio-group v-model="formulaTypeVal" @change="mixstationChange">
                                <el-radio
                                    v-for="item in formulaTypeData"
                                    :label="item.value"
                                    :key="item.value"
                                >
                                    {{ item.label }}
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="right_box">
                <div>
                    <div class="title">
                        配比类型：
                    </div>
                    <div class="content">
                        <el-radio-group v-model="formulaTypeVal" @change="mixstationChange">
                            <el-radio
                                v-for="item in formulaTypeData"
                                :label="item.value"
                                :key="item.value"
                            >
                                {{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div>
                    <div class="title">
                        试验配比名称：
                    </div>
                    <div class="content">
                        {{ productFormulaCount.experiment_formula_name }}
                    </div>
                    <div class="title">
                        试验配比编号：
                    </div>
                    <div class="content">
                        {{ productFormulaCount.line_experiment_formula_number }}
                    </div>
                </div>
            </div>
        </div>
        <div class="params-wrapper">
            <div class="params-item params1">
                <div class="name">
                    设计容量:
                </div>
                <div class="num">
                    {{ productFormulaCount.design_volume_weight }}<i>kg</i>
                </div>
            </div>
            <div class="params-item params2">
                <div class="name">
                    实际容重:
                </div>
                <div class="num">
                    {{ productFormulaCount.practical_volume_weight }}<i>kg</i>
                </div>
            </div>
            <div class="params-item params3">
                <div class="name">
                    坍落度:
                </div>
                <div class="num">
                    {{ productFormulaCount.slump }}<i>mm</i>
                </div>
            </div>
            <div class="params-item params4">
                <div class="name">
                    水胶比:
                </div>
                <div class="num">
                    {{ productFormulaCount.water_binder_ratio }}
                </div>
            </div>
            <div class="params-item params5">
                <div class="name">
                    砂率:
                </div>
                <div class="num">
                    {{ productFormulaCount.sand_rate }}<i>%</i>
                </div>
            </div>
            <div class="params-item params6">
                <div class="name">
                    最大粒径:
                </div>
                <div class="num">
                    {{ productFormulaCount.max_particle_size }}<i>mm</i>
                </div>
            </div>
            <div class="params-item params7">
                <div class="name">
                    凝胶材料:
                </div>
                <div class="num">
                    {{ productFormulaCount.cementing_material }}
                </div>
            </div>
            <div class="params-item params8">
                <div class="name">
                    外加剂掺量:
                </div>
                <div class="num">
                    {{ productFormulaCount.admixture_dosage }}<i>%</i>
                </div>
            </div>
        </div>
        <div class="list-wrapper table-bg">
            <div class="table_body">
                <table>
                    <thead>
                        <tr>
                            <th style="width:0.7rem;" rowspan="2">
                                <div>
                                    工控编号
                                </div>
                            </th>
                            <th style="width:1rem;" rowspan="2">
                                <div>
                                    工控料仓类型
                                </div>
                            </th>
                            <th class="line" rowspan="2"></th>
                            <th :colspan="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id?8:6">
                                <div>
                                    施工配比设置
                                </div>
                            </th>
                            <th class="line"></th>
                            <th colspan="8">
                                <div>
                                    理论配比管控
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th style="width:0.8rem;">
                                <div>
                                    原料分类
                                </div>
                            </th>
                            <th style="width:0.8rem;">
                                <div>
                                    原料子类
                                </div>
                            </th>
                            <th style="width:0.8rem;">
                                <div>
                                    原料规格
                                </div>
                            </th>
                            <th style="width:0.8rem;">
                                <div>
                                    试验配比<br>用量(kg)
                                </div>
                            </th>
                            <th style="width:0.8rem;" v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id">
                                <div>
                                    原施工配比<br>用量(kg)
                                </div>
                            </th>
                            <th
                                style="width:0.8rem;"
                                class="constructionConsumption"
                            >
                                <div>
                                    施工配比<br>用量(kg)
                                </div>
                            </th>
                            <th v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id" style="width:0.7rem;">
                                <div>
                                    调整差值
                                </div>
                            </th>
                            <th style="width:0.8rem;">
                                <div>
                                    含水率(%)
                                </div>
                            </th>
                            <th class="line" rowspan="2"></th>
                            <th>
                                <div>
                                    原料分类
                                </div>
                            </th>
                            <th>
                                <div>
                                    原料子类
                                </div>
                            </th>
                            <th>
                                <div>
                                    原料规格
                                </div>
                            </th>
                            <th style="width:0.8rem;">
                                <div>
                                    差值
                                </div>
                            </th>
                            <th class="constructionConsumption">
                                <div>
                                    施工配比<br>用量(kg)
                                </div>
                            </th>
                            <th>
                                <div>
                                    理论配比<br>用量(kg)
                                </div>
                            </th>
                            <th>
                                <div>
                                    最小值(kg)
                                </div>
                            </th>
                            <th>
                                <div>
                                    最大值(kg)
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item,index) in tableData">
                            <tr :key="index">
                                <template v-if="item.constructionRatio.length > 0">
                                    <td style="width:0.7rem;" title="工控编号">
                                        {{ item.constructionRatio[0].plcw || '——' }}
                                    </td>
                                    <td
                                        title="工控料仓类型"
                                        style="width:1rem; color: #0078FB; text-decoration: underline;cursor: pointer"
                                        @click="JumpChange(item.constructionRatio[0])"
                                    >
                                        {{ item.constructionRatio[0].plcwname|| '——' }}
                                    </td>
                                    <td class="line"></td>
                                    <td style="width:1rem;" title="原料分类">
                                        {{ item.constructionRatio[0].stock_category_name|| '——' }}
                                    </td>
                                    <td style="width:1rem;" title="原料子类">
                                        {{ item.constructionRatio[0].material_child_name|| '——' }}
                                    </td>
                                    <td style="width:1rem;" title="原料规格">
                                        {{ item.constructionRatio[0].material_name|| '——' }}
                                    </td>
                                    <td style="width:1rem;" title="试验配比用量(kg)">
                                        {{ item.constructionRatio[0].experiment_quantity|| '——' }}
                                    </td>
                                    <td
                                        style="width:1rem;"
                                        title="原施工配比用量(kg)"
                                        v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id"
                                    >
                                        {{ item.constructionRatio[0].original_product_quantity|| '——' }}
                                    </td>
                                    <td style="width:1rem;" title="施工配比用量(kg)" class="constructionConsumption">
                                        {{ item.constructionRatio[0].product_quantity|| '——' }}
                                    </td>
                                    <td
                                        title="调整差值"
                                        :class="[ item.constructionRatio[0].differ_value < 0 ? 'red' : 'green' ]"
                                        v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id"
                                    >
                                        {{ item.constructionRatio[0].differ_value|| '——' }}
                                        <span v-if="item.constructionRatio[0].differ_value" class="iconfont iconjiang"></span>
                                    </td>
                                    <td style="width:1rem;" title="含水率(%)">
                                        {{ item.constructionRatio[0].stock_category == 'F3-01-XN-01'?
                                            item.constructionRatio[0].moisture_ratio : '——' }}
                                    </td>
                                    <td class="line"></td>
                                </template>
                                <template v-else>
                                    <td>——</td>
                                    <td>——</td>
                                    <td class="line"></td>
                                    <td>——</td>
                                    <td>——</td>
                                    <td>——</td>
                                    <td>——</td>
                                    <td>——</td>
                                    <td>——</td>
                                    <template v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id">
                                        <td>——</td>
                                        <td>——</td>
                                    </template>
                                    <td class="line"></td>
                                </template>
                                <td title="原料分类" :rowspan="rowspanCalculation(item)">
                                    {{ item.stock_category_name|| '——' }}
                                </td>
                                <td title="原料子类" :rowspan="rowspanCalculation(item)">
                                    {{ item.material_child_name|| '——' }}
                                </td>
                                <td title="原料规格" :rowspan="rowspanCalculation(item)">
                                    {{ item.material_name|| '——' }}
                                </td>
                                <td
                                    title="差值"
                                    :rowspan="rowspanCalculation(item)"
                                    :class="[ item.difference_quantity < 0 ? 'red' : 'green' ]"
                                >
                                    {{ item.difference_quantity || '——' }}
                                    <span v-if="item.difference_quantity" class="iconfont iconjiang"></span>
                                </td>
                                <td
                                    title="施工配比用量（kg）"
                                    class="constructionConsumption"
                                    :rowspan="rowspanCalculation(item)"
                                    :class="{'overrun': item.product_quantity
                                        && item.product_quantity < item.theory_quantity_min
                                        && item.product_quantity > item.theory_quantity_max}"
                                >
                                    <i class="iconfont iconyujing"></i>
                                    {{ item.product_quantity || '——' }}
                                </td>
                                <td title="理论配比用量(kg)" :rowspan="rowspanCalculation(item)">
                                    {{ item.theory_quantity || '——' }}
                                </td>
                                <td title="最小值(kg)" :rowspan="rowspanCalculation(item)">
                                    {{ item.theory_quantity_min || '——' }}
                                </td>
                                <td title="最大值(kg)" :rowspan="rowspanCalculation(item)">
                                    {{ item.theory_quantity_max || '——' }}
                                </td>
                            </tr>
                            <template v-if="item.constructionRatio.length > 1">
                                <template v-for="(k, j) in item.constructionRatio">
                                    <tr :key="`tr_${k.plcw}`" v-if="j>0">
                                        <td style="width:0.7rem;" title="工控编号">
                                            {{ k.plcw || '——' }}
                                        </td>
                                        <td
                                            title="工控料仓类型"
                                            @click="JumpChange(k)"
                                            style="width:1rem; color: #0078FB; text-decoration: underline;cursor: pointer"
                                        >
                                            {{ k.plcwname || '——' }}
                                        </td>
                                        <td class="line"></td>
                                        <td style="width:1rem;" title="原料分类">
                                            {{ k.stock_category_name || '——' }}
                                        </td>
                                        <td style="width:1rem;" title="原料子类">
                                            {{ k.material_child_name || '——' }}
                                        </td>
                                        <td style="width:1rem;" title="原料规格">
                                            {{ k.material_name || '——' }}
                                        </td>
                                        <td style="width:1rem;" title="试验配比用量(kg)">
                                            {{ k.experiment_quantity || '——' }}
                                        </td>
                                        <td
                                            style="width:1rem;"
                                            title="原施工配比用量(kg)"
                                            v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id"
                                        >
                                            {{ item.constructionRatio[0].original_product_quantity|| '——' }}
                                        </td>
                                        <td style="width:1rem;" title="施工配比用量(kg)" class="constructionConsumption">
                                            {{ k.product_quantity || '——' }}
                                        </td>
                                        <td
                                            title="调整差值"
                                            :class="[ item.constructionRatio[0].differ_value < 0 ? 'red' : 'green' ]"
                                            v-if="timeRecords.length>0&&adjust_id!==timeRecords[timeRecords.length-1].adjust_id"
                                        >
                                            {{ item.constructionRatio[0].differ_value|| '——' }}
                                            <span v-if="item.constructionRatio[0].differ_value" class="iconfont iconjiang"></span>
                                        </td>
                                        <td style="width:1rem;" title="含水率(%)">
                                            {{ k.stock_category == 'F3-01-XN-01'?k.moisture_ratio:'——' }}
                                        </td>
                                        <td class="line"></td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import NP from 'number-precision';

export default {
    name: 'view-mix-proportion',
    props: {
        extr: {
            type: [Array, Object, String],
        },
    },
    data() {
        return {
            Loading: false,
            // 施工配比基本信息
            productFormula: {
                project_name: '',
                project_detail: '',
                is_datum: false,
                is_testblock: false,
            },

            // 全部生产机组
            mixstationlineData: [],
            mixstationlineDataCarousel: [],
            // 机组选中项code
            mixstationChecked: null,
            // 机组选中项名称
            mixstationName: '',
            // 机组选中项下标
            mixstationIndex: null,

            // 色块字段取值
            productFormulaCount: {
                design_volume_weight: 0,
                practical_volume_weight: 0,
                slump: 0,
                water_binder_ratio: 0,
                sand_rate: 0,
                max_particle_size: 0,
            },

            // 全部配比类型
            formulaTypeData: [
                {
                    label: '砼配比',
                    value: 0,
                },
                {
                    label: '润泵砂浆配比',
                    value: 1,
                },
                {
                    label: '润泵砂浆配比（标号）',
                    value: 2,
                },
            ],
            // 配比类型绑定字段
            formulaTypeVal: 0,
            // 配比类型选中项
            formulaTypeChecked: 0,

            // 列表数据
            tableData: [],

            // 时间轴列表
            timeRecords: [],

            // 选中的时间轴id
            adjust_id: '',
        };
    },
    watch: {
        formulaTypeVal(newVal) {
            if (newVal === 0) {
                this.formulaTypeChecked = 0;
            } else if ([1, 2].indexOf(newVal) > -1) {
                this.formulaTypeChecked = 1;
            }
        },
    },
    created() {
        if (this.extr && this.extr.taskNumber) {
            if (this.extr.isReadOnly) {
                this.isReadOnly = this.extr.isReadOnly;
            }
            this.task_number = this.extr.taskNumber;
            // 初始化页面数据
            this.getInit();
        }
    },
    methods: {
        // 初始化页面数据
        async getInit() {
            this.Loading = true;
            const res = await this.getInitRequest();
            if (res) {
                this.productFormula = res.productFormula;
                this.productFormula.mark_number = this.productFormula.strength_grade_name + this.productFormula.special_require_name;
                this.mixstationlineData = res.productFormulaLine;
                this.productFormulaLine = res.productFormulaLine;
                let carArr = [];
                const allArr = [];
                res.productFormulaLine.forEach((item, i) => {
                    carArr.push(item);
                    if (carArr.length === 3 || i === res.productFormulaLine.length - 1) {
                        allArr.push(carArr);
                        carArr = [];
                    }
                });
                console.log(allArr, 'allArr');
                this.mixstationlineDataCarousel = allArr;
                if (this.mixstationlineData.length > 0) {
                    if (!this.mixstationChecked) {
                        this.mixstationChecked = this.mixstationlineData[0].line_code;
                        this.mixstationName = this.mixstationlineData[0].line_name;
                        this.mixstationIndex = 0;
                    }
                    this.mixstationChange();
                }
            }
            this.Loading = false;
        },
        // 切换机组
        switchingUnit(item) {
            console.log(item, 'itemmmm');
            this.mixstationChecked = item.line_code;
            this.mixstationName = item.line_name;
            this.mixstationIndex = item.line_code;
            this.mixstationChange();
        },
        // 切换列表数据
        mixstationChange() {
            this.getAdjustTimeRecord();

            this.refreshTableData();
        },

        // 刷新列表数据
        refreshTableData() {
            this.tableData = [];
            this.productFormulaCount = {};
            this.mixstationName = this.mixstationlineData.filter(item => item.line_code === this.mixstationChecked)[0].line_name;
            const tableData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            });
            if (tableData.length > 0) {
                if (this.formulaTypeChecked === 0) {
                    if (tableData[0].product_formula_hjt) {
                        this.processingData(tableData[0].product_formula_hjt);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                } else if (this.formulaTypeChecked === 1) {
                    if (tableData[0].product_formula_sj) {
                        this.processingData(tableData[0].product_formula_sj);
                    } else {
                        this.tableData = [];
                        this.productFormulaCount = {};
                    }
                }
            } else {
                this.tableData = [];
                this.productFormulaCount = {};
            }
        },
        // 数据处理
        processingData(res) {
            let newData = [];
            const data = JSON.parse(JSON.stringify(res));
            this.productFormulaCount = JSON.parse(JSON.stringify(data));

            data.productFormulaRelExperimentOutputs.forEach(z => {
                z.constructionRatio = [];
            });

            const correlationRatio = [];
            data.productFormulaEntityOutputs.forEach(k => {
                data.productFormulaRelExperimentOutputs.forEach(item => {
                    if (item.material_code !== ''
                        && k.material_code === item.material_code
                        && (item.material_bg_code !== '' && k.material_bg_code === item.material_bg_code || (item.material_bg_code || '') === '' )
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && item.material_child_name !== ''
                        && k.material_child_name === item.material_child_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    } else if ((item.material_code === '' || k.material_code === '')
                        && (item.material_child_name === '' || k.material_child_name === '' )
                        && item.stock_category_name !== ''
                        && k.stock_category_name === item.stock_category_name
                    ) {
                        correlationRatio.push(k);
                        item.constructionRatio.push(k);
                    }
                });
            });
            // eslint-disable-next-line max-len
            const row = data.productFormulaEntityOutputs.filter(item => !correlationRatio.some(ele => ele.material_code === item.material_code && ele.material_child_name === item.material_child_name && ele.stock_category_name === item.stock_category_name));
            // 有工控仓位没有试验配比，试验配比新增一条数据，把工控仓位合并到试验配比数据里
            const unmatchedData = [];

            const result = row.reduce((a, b) => {
                const codeName = `${b.material_code}_${b.material_child_name}_${b.stock_category_name}`;
                if (a[codeName]) {
                    a[codeName].push(b);
                } else {
                    a[codeName] = [b];
                }
                return a;
            }, {});

            for (const item in result) {
                unmatchedData.push({
                    constructionRatio: [...result[item]],
                });
            }

            newData = [...data.productFormulaRelExperimentOutputs, ...unmatchedData];

            newData.forEach(item => {
                if (item.constructionRatio.length > 0) {
                    item.sort = item.constructionRatio[0].plcw;
                } else {
                    item.sort = '999';
                }
            });

            newData.sort(function (a, b) {
                return a.sort - b.sort;
            });

            this.tableData = newData;

            this.calculation_BasicInfoValue();

            this.$forceUpdate();
        },

        // 计算基本信息（容重、水胶比、砂率、外加剂掺量、胶凝材料）
        calculation_BasicInfoValue() {
            let practical_volume_weight = 0; // 容重
            let fineAggregate = 0; // 细骨料
            let coarseAggregate = 0; // 粗骨料
            let water = 0; // 水
            let powder = 0; // 粉料
            let cement = 0; // 水泥
            let admixtures = 0; // 掺合料（分类，字典值205-开头的）
            let powderyExpansiveAgent = 0; // 粉状膨胀剂（规格：外加剂-膨胀剂-粉状膨胀剂）
            let admixture = 0; // 外加剂(排除粉状膨胀剂)

            const rowData = [];
            this.tableData.forEach(item => {
                rowData.push(...item.constructionRatio);
            });
            rowData.forEach(k => {
                practical_volume_weight += Number(Number(k.product_quantity || 0).toFixed(2)) * 100;
                if (k.stock_category_dictcode === '203') {
                    fineAggregate = NP.plus(fineAggregate, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '204') {
                    coarseAggregate = NP.plus(coarseAggregate, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '207') {
                    water = NP.plus(water, k.product_quantity || 0);
                }
                // eslint-disable-next-line max-len
                if (['202', '205-FHL', '205-GHJ', '205-FMH', '205-GH0', '205-GZF', '205-KZF', '205-LZF', '205-SHF'].indexOf(k.stock_category_dictcode) > -1) {
                    powder = NP.plus(powder, k.product_quantity || 0);
                }
                if (k.stock_category_dictcode === '202') {
                    cement = NP.plus(cement, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode.indexOf('205-') > -1) {
                    admixtures = NP.plus(admixtures, k.product_quantity || 0);
                }

                if (k.stock_category_dictcode === '208') {
                    if (k.material_child_dictcode === '208-PZJ' && k.material_code === '208-PZJ-01') {
                        powderyExpansiveAgent = k.product_quantity;
                    }
                    if (k.material_code !== '208-PZJ-01') {
                        admixture = NP.plus(admixture, k.product_quantity || 0);
                    }
                }
            });
            // 砂率
            if (fineAggregate > 0) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.sand_rate = Number(NP.times(NP.divide(fineAggregate || 0, NP.plus(fineAggregate || 0, coarseAggregate || 0)), 100)).toFixed(2);
            } else {
                this.productFormulaCount.sand_rate = 0;
            }

            // 水胶比
            if (water > 0 && powder > 0) {
                this.productFormulaCount.water_binder_ratio = NP.divide(water, powder).toFixed(2);
            } else {
                this.productFormulaCount.water_binder_ratio = 0;
            }

            // 容重
            this.productFormulaCount.practical_volume_weight = Number(NP.divide(practical_volume_weight, 100)).toFixed(2);

            // 胶凝材料
            this.productFormulaCount.cementing_material = Number(NP.plus(cement, admixtures, powderyExpansiveAgent));

            // 外加剂掺量
            if (this.productFormulaCount.cementing_material) {
                // eslint-disable-next-line max-len
                this.productFormulaCount.admixture_dosage = Number(NP.times(NP.divide(admixture, this.productFormulaCount.cementing_material), 100)).toFixed(2);
            } else if (admixture > 0) {
                this.productFormulaCount.admixture_dosage = 100;
            } else {
                this.productFormulaCount.admixture_dosage = 0;
            }
        },

        // 合并列
        rowspanCalculation(row) {
            let new_rowspan = 1;
            if (row.constructionRatio.length > 1) {
                new_rowspan = row.constructionRatio.length;
            }
            return new_rowspan;
        },
        // 获取上线企业列表
        getInitRequest() {
            const path = `/interfaceApi/production/productformula/formulawithline/${this.extr.taskNumber}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 改变时间记录选中选项
        changeTimeRecord(item) {
            this.adjust_id = item.adjust_id;
            this.getTimeRecordInfo();
        },
        // 获取配合比调整
        getAdjustTimeRecord() {
            const queryStr = `task_number=${this.task_number}&line_code=${this.mixstationChecked}&formula_type=${this.formulaTypeVal}`;
            this.$axios.get('/interfaceApi/production/productformula/formula_adjust_time_axis?' + queryStr,)
                .then(res => {

                    this.timeRecords = res || [];
                    if (this.timeRecords.length > 0) {
                        // 默认选中最后一个
                        this.adjust_id = res[this.timeRecords.length - 1].adjust_id;
                    }
                });
        },

        // 获取根据时间轴 版本数据
        getTimeRecordInfo() {
            const data = {
                adjust_id: this.adjust_id,
                line_code: this.mixstationChecked,
                formula_type: this.formulaTypeVal,
                productFormulaEntityOutputs: null,
                productFormulaRelExperimentOutputs: null,
            };
            const filterData = this.productFormulaLine.filter(item => {
                return item.line_code === this.mixstationChecked;
            })[0];
            let dataInstance = null;
            if (this.formulaTypeVal === 0 && filterData.product_formula_hjt) {
                dataInstance = filterData.product_formula_hjt;
            } else if (this.formulaTypeVal !== 0 && filterData.product_formula_number_sj) {
                dataInstance = filterData.product_formula_number_sj;
            }
            if (dataInstance) {
                data.productFormulaEntityOutputs = dataInstance.productFormulaEntityOutputs;
                data.productFormulaRelExperimentOutputs = dataInstance.productFormulaRelExperimentOutputs;
            }
            const path = '/interfaceApi/production/productformula/formula_adjust_info';
            this.$axios.$post(path, data, { defEx: true }).then(res => {
                dataInstance.productFormulaEntityOutputs = res.productFormulaEntityOutputs;
                dataInstance.productFormulaRelExperimentOutputs = res.productFormulaRelExperimentOutputs;
                this.refreshTableData();
            });
        },


        // 工控料仓类型弹出
        JumpChange(row) {
            this.$toast(
                {
                    title: true,
                    text: '工控与ERP关系',
                    type: 'eject',
                    width: '13rem',
                    height: 'auto',
                    t_url: 'technicalQualityManagement/concreteQualityTrace/centralControlConfig',
                    extr: {
                        mixstation_code: this.$takeTokenParameters('StationId'),
                        line_code: this.mixstationChecked,
                        plcw: row.plcw,
                    },
                }
            );
        },
        // 导出
        exportExcell() {
            if (!this.adjust_id) {
                this.$message.warning('暂无数据');
                return;
            }
            this.loading = true;
            let pre_adjust_id = '';
            const index = this.timeRecords.findIndex(item => item.adjust_id === this.adjust_id);
            if (index > 0) {
                pre_adjust_id = this.timeRecords[index - 1].adjust_id;
            }
            const data = {
                task_number: this.task_number,
                line_code: this.mixstationChecked,
                formula_type: this.formulaTypeVal,
                adjust_id: this.adjust_id,
                pre_adjust_id: pre_adjust_id,
            };
            const qs = require('qs');
            const querystr = qs.stringify(data);
            this.$axios
                .get('/interfaceApi/production/productformula/export_formula_adjust?' + querystr, { responseType: 'arraybuffer' })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'application/octet-stream' });
                    let fileName = res.headers['content-disposition'].split('filename*=UTF-8\'\'')[1];
                    fileName = decodeURIComponent(fileName);
                    if ('download' in document.createElement('a')) { // 非IE下载
                        const elink = document.createElement('a');
                        elink.download = fileName;
                        elink.style.display = 'none';
                        elink.href = URL.createObjectURL(blob);
                        document.body.appendChild(elink);
                        elink.click();
                        URL.revokeObjectURL(elink.href); // 释放URL 对象
                        document.body.removeChild(elink);
                    } else { // IE10+下载
                        navigator.msSaveBlob(blob, fileName);
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>

<style lang="stylus" scoped>
.viewMixProportion
    width 100%;
    height 100%;
    padding .1rem
    .base-info
        display flex
        border 1px solid #D7D7D7
        .left-box
            width 8rem
            height 1.3rem
            .title
                padding 0.15rem 0 0.08rem 0.2rem
                font-weight bold
                font-size 0.14rem;
                color #333333
        .machine-list
            display flex
            padding 0.1rem
            .carousel_box
                width 100%
                .item_box
                    display flex
                    .machine-item
                        box-sizing border-box
                        padding 0.07rem
                        margin 0 0.08rem
                        width: 2.5rem;
                        height: 0.92rem;
                        background: #F5F9FF;
                        border: 1px solid #D7D7D7;
                        display flex
                        flex-direction column
                        justify-content space-around
                        position relative
                        cursor: pointer;
                        &.active
                            background: #ECF7FF;
                            box-shadow: 0 0.02rem 0.06rem 0 rgba(0, 0, 0, 0.6);
                            border: 0.02rem solid #1890FF;
                        .active_icon
                            width .25rem
                            height .25rem
                            position absolute
                            right 0
                            bottom -1px
                            background url(../images/active.png) no-repeat
                            background-size 100% 100%
                        .machine_save
                            background #3BA271 !important
                        .machine_title
                            font-weight bold
                            font-size 0.14rem;
                            color #fff !important
                            padding 0 .04rem
                            background #F98552
                            i
                                display inline-block
                                width 0.2rem
                                height 0.2rem
                                float right
                                margin-top.05rem
                                &.toSave
                                    background url(../images/save@2x.png) no-repeat
                                    background-size 100% 100%
                                &.toSend
                                    background url(../images/send@2x.png) no-repeat
                                    background-size 100% 100%
                            span
                                float right
                        span
                            font-size 0.12rem
                            &.nullSpan
                                color #979EA7
                        .send-btn
                            display flex
                            justify-content center
                            align-items center
                            width: 0.4rem;
                            height: 0.2rem;
                            border-radius: 0.02rem;
                            border: 1px solid #1890FF;
                            font-size 0.12rem
                            color #1890FF
                            position absolute
                            top 0.1rem;
                            right 0.1rem;
                            &.active
                                color #fff;
                                background #1890FF
        .right_box
            flex 1
            height .92rem
            margin .1rem .1rem 0 0
            >div
                width 100%
                height 50%
                border 1px solid #D7D7D7
                display flex
                padding .02rem
                >div
                    background #F8F8F8
                    display flex
                    align-items center
                .title
                    width 1.5rem
                    padding 0 .05rem
                    margin 0 .02rem 0 .02rem
                    justify-content end
                    font-size .18rem !important
                .content
                    min-width 2.2rem
                    padding 0 .05rem
                    font-size .16rem !important
                &:nth-child(2)
                    border-top none
    .right-box
        width: calc(100%);
        border 1px solid #D7D7D7
        .mix-time-line
            width 100%;
            .title
                height 0.5rem;
                display flex
                justify-content center
                align-items center
                background #F8F8F8
                position relative
                margin .03rem
                font-size 0.18rem;
                color #333333
                .peo-box
                    position absolute
                    top 0.12rem
                    right 0.6rem
                    span
                        margin 0.05rem
                        padding 0.01rem 0.03rem;
                        font-size 0.12rem;
                        &.adjust-peo
                            background #EFF6FF
                            border: 1px solid #006EFF;
                            color #006EFF
                        &.approver-peo
                            background #FFF8F5
                            border: 1px solid #FC8450;
                            color #FC8450
                        &.versions-peo
                            background #EBF8F2
                            border: 1px solid #3BA271;
                            color #3BA271
            .time-line-box
                width 100%;
                height .8rem;
                display flex
                padding-left 0.15rem
                overflow-x auto
                .time-line-item
                    width 3rem
                    height .65rem;
                    display flex
                    align-items center
                    cursor: pointer;
                    flex-shrink 0
                    clip-path polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%)
                    // border 1px solid #D7D7D7
                    background #EFF6FF
                    padding 0 .25rem
                    font-size .16rem
                    margin .05rem 0
                    // &:after
                    //     display inline-block
                    //     width 100%
                    //     height 0.02rem
                    //     content ""
                    //     background #0091FF
                    .pot-info
                        width 100%
                        height 100%;
                        padding 0.04rem 0
                        display flex
                        flex-direction column
                        justify-content center
                        align-items center
                        z-index 9
                        .peo-names
                            width 100%;
                            display flex
                            justify-content center
                            align-items center
                            .peo-name
                                margin 0 3px
                                padding 0.02rem 0.1rem
                                display flex
                                justify-content start
                                align-items center
                                height 0.2rem;
                                background #E6F7FF
                                border 1px solid #0091FF
                                white-space nowrap
                                font-size 0.14rem;
                                color #0084FE
                                &.peo2
                                    background #FFFBE6
                                    border 1px solid #F7B500
                                    color #FA6400
                                &.peo3
                                    background #EBF8F2
                                    border 1px solid #3BA271
                                    color #3BA271
                        .date
                            color #666666
                        // .date
                        //     margin 0.08rem 0;
                        //     height 0.26rem
                        //     padding 1px 1px 1px 0.03rem;
                        //     font-size 0.14rem
                        //     color #0091FF
                        //     background: #fff;
                        //     border: 1px solid #68BEFF;
                        //     border-radius: 2px;
                        //     position relative
                        //     white-space nowrap
                        //     span
                        //         padding 0 0.03rem
                        //     &:before
                        //         box-sizing: content-box;
                        //         width: 0px;
                        //         height: 0px;
                        //         position: absolute;
                        //         top: 0.23rem;
                        //         left: 0.58rem;
                        //         padding: 0;
                        //         border-right: 0.04rem solid transparent;
                        //         border-top: 0.04rem solid #fff;
                        //         border-bottom: 0.04rem solid transparent;
                        //         border-left: 0.04rem solid transparent;
                        //         display: block;
                        //         content: '';
                        //         z-index: 12;
                        //     &:after
                        //         box-sizing: content-box;
                        //         width: 0px;
                        //         height: 0px;
                        //         position: absolute;
                        //         top: 0.25rem;
                        //         left: 0.58rem;
                        //         padding: 0;
                        //         border-right: 0.04rem solid transparent;
                        //         border-top: 0.04rem solid #68BEFF;
                        //         border-bottom: 0.04rem solid transparent;
                        //         border-left: 0.04rem solid transparent;
                        //         display: block;
                        //         content: '';
                        //         z-index: 10;
                        //     label
                        //         display inline-block
                        //         width: 0.5rem;
                        //         height: 100%;
                        //         background: linear-gradient(90deg, #F5E6D7 0%, #CEA993 100%);
                        //         text-align center
                        .times
                            color #666666
                            font-size 0.14rem;
                            white-space nowrap
                    .endIcon
                        position: absolute;
                        right: -0.06rem;
                        color #0091FF
                        font-size 0.32rem
                    &.active
                        background url(../images/jxbk.png) no-repeat
                        background-size 100% 100%
                        height 0.87rem !important
                        margin 0 !important
                        margin-top -.07rem !important
                    //     .pot-info
                    //         .times
                    //             color #FA6400
                    //         .date
                    //             border 1px solid #FA6400;
                    //             color #fff;
                    //             background: #FA6400;
                    //             box-shadow: 0rem 0.02rem 0.04rem 0rem rgba(250, 100, 0, 0.3);
                    //             label
                    //                 background #fff;
                    //                 color #FA6400
                    //              &:before,&:after
                    //                  border-top: 0.04rem solid #FA6400;
    .wrapper-box
        display flex
        border 1px solid #D7D7D7
        margin .1rem 0
        height .4rem
        .item
            font-size 0.14rem;
            display flex
            width 33%
            &:last-child
                margin-right 0;
            .el-radio
                margin-right 0.05rem;
                white-space nowrap
                >>> .el-radio__label
                    padding-left 0.03rem;
                    font-size 0.14rem
            >div
                width 100%
                margin .02rem
                display flex
                align-items center
                background #F8F8F8
                padding-left .1rem
                &:nth-child(1)
                    color #333
                    justify-content center
                    width 1.2rem
                &:nth-child(2)
                    color #666
                    flex 1
    .params-wrapper
        margin-top .1rem
        display flex
        justify-content space-between
        border 1px solid #D7D7D7
        padding .02rem
        .params-item
            width: 2rem;
            height: 0.35rem;
            font-size .14rem
            display flex
            .name
                display flex
                justify-content flex-end
                align-items center
                width: .9rem;
                height: 0.35rem;
                padding-right .02rem
                background: rgba(85, 136, 241, 0.1);
            .num
                padding 0 0.02rem 0 .01rem
                flex 1
                display flex
                justify-content flex-start
                align-items center
                color #333333
                background #F8F8F8
                font-size 0.18rem
                margin-left .02rem
                position relative
                i
                    color #9B9B9B
                    font-size 0.14rem
                    position absolute
                    right .01rem
            &.params1
                .name
                    background #F3F6FF
                    color #556FC6
            &.params2
                .name
                    background #FDF5E3
                    color #FBC95D
            &.params3
                .name
                    background #EFFAFE
                    color #73C0DE
                .num
                    background #F8F8F8
            &.params4
                .name
                    background #F7F3F8
                    color #9B60B4
            &.params5
                .name
                    background #F7EDE9
                    color #FB8450
            &.params6
                .name
                    background #F1F9ED
                    color #81BA68
            &.params7
                .name
                    background #EFF6FF
                    color #5DA1FB
            &.params8
                .name
                    background #EDF5F1
                    color #3FA474
    .list-wrapper
        margin-top 0.2rem
        height calc(100% - 2.9rem);
        overflow auto
        .table_body
            table
                width 100%
                table-layout inherit
                word-break break-all
                border-collapse collapse
                thead
                    tr
                        &:first-child
                            .line
                                border-top 1px solid #fff
                        &:last-child
                            th
                                background #F6F8FC
                th,td
                    font-size 0.14rem
                    line-height 0.16rem
                    text-align center
                    height 0.36rem
                    border 1px solid #D6CEC9
                    padding .02rem
                    background #fff !important
                    div
                        width 100%
                        height 100%
                        background #F8F8F8 !important
                        display flex
                        justify-content center
                        align-items center
                    &.line
                        width 4px
                        border-top none
                        border-bottom none
                th
                    color #333333
                    border 1px solid #D6CEC9
                td
                    &.constructionRatio
                        border none
                    >i
                        display none
                    >p
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    .iconjiang
                        display inline-block
                        margin-left -0.05rem
                        font-size 0.16rem;
                    &.red
                        color #E02020
                    &.green
                        color #019E7C
                        .iconjiang
                            transform rotate(180deg)
                    &.overrun
                        color #0078FB
                        i
                            font-size 0.14rem
                            vertical-align top
                            display inline-block
                .constructionConsumption
                    color #0078FB
                >>>.constructionConsumption_input
                    .el-input__inner
                        color #0078FB
</style>
